import { createApp } from 'vue';
import App from './App.vue';

import PrimeVue from 'primevue/config';

import Button from "primevue/button";
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import MultiSelect from "primevue/multiselect";
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import ProgressSpinner from "primevue/progressspinner";
import SelectButton from 'primevue/selectbutton';
import Password from 'primevue/password';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import Paginator from 'primevue/paginator';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import OverlayPanel from 'primevue/overlaypanel';
import Tooltip from 'primevue/tooltip';
import Login from "@/components/Login.vue";

import MegaMenu from 'primevue/megamenu';

import ScrollPanel from 'primevue/scrollpanel';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Divider from 'primevue/divider';
import Message from 'primevue/message';

import router from './router';
import 'primeflex/primeflex.css';
import store from './store';
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ConfirmPopup from "primevue/confirmpopup";
import axios from "axios";
import Badge from "primevue/badge";
import Card from "primevue/card";
import Chips from "primevue/chips";

const app = createApp(App).use(router).use(store);
app.config.globalProperties.$store=store;
app.mount('#app');

app.use(PrimeVue);
app.use(ConfirmationService);

app.component('Button', Button);
app.component('Chips', Chips);
app.component('Checkbox', Checkbox);
app.component('RadioButton', RadioButton);
app.component('InputSwitch', InputSwitch)
app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Textarea', Textarea);
app.component('InputMask', InputMask);
app.component('Calendar', Calendar);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Login', Login);
app.component('SelectButton', SelectButton);
app.component('Password', Password);
app.component('Toast', Toast);
app.component('ConfirmPopup', ConfirmPopup);
app.component('Badge', Badge);
app.use(ToastService);

app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Row', Row);
app.component('Paginator', Paginator);

app.component('TabView', TabView);
app.component('TabPanel', TabPanel);

app.component('MegaMenu', MegaMenu);

app.component('OverlayPanel', OverlayPanel);
app.directive('tooltip', Tooltip);
app.component('ScrollPanel', ScrollPanel);
app.component('Dialog', Dialog);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Divider', Divider);
app.component('Message', Message);
app.component('Card', Card);

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    error && error.response && error.response.data &&
        console.log(error.response.data)
    if (error && error.response && error.response.status && error.response.status === 401) {
        localStorage.setItem("token", null);
        router.push("/login");
    }
    return Promise.reject(error)
})
