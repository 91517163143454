import axios from 'axios';
import AuthService from "@/service/auth.service";

const state = {
    token: null,
};
const getters = {
    isAuthenticated: state => !!state.token,
};
const actions = {
    async login({commit}, user) {
        let token = null;
        await axios.post(`${process.env.VUE_APP_PATH_PREFIX || "/backend"}/auth/login`, {
            username: user.username,
            password: user.password
        }).then((data) => {
            token = data.data.access_jwt_token;
            //this.$router.push("/jobs");
        }).catch((error) => {
            token = "";
            console.log(`Got error while trying to login: ${JSON.stringify(error)}`);
        })
        await commit('setToken', token);
    },
};

const mutations = {
    setToken(state, token) {
        state.token = token
        if (!token) {
            localStorage.removeItem("token");
        } else {
            localStorage.setItem("token", token);
        }
    },
    LogOut(state){
        state.token = null
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
