<template>
  <main>
    <div class="inner">
      <router-view></router-view>
    </div>
  </main>
</template>

<script setup>

</script>

<style scoped>

</style>