<template>

  <div class="sidebar">
    <!-- LOGO START -->
    <a href="/" class="logo">
    </a>
    <!-- LOGO END -->

    <!-- MENU START -->
    <MegaMenu :model="items" orientation="vertical">
      <template #item="{ label, item, props, hasSubmenu }">
        <router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
          <a :href="routerProps.href" v-bind="props.action" @click="routerProps.navigate"
             :class="{ 'active-link': isActiveRoute(item) }">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else-if="item.command" @click="item.command" v-bind="props.action">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
        </a>
        <a v-else v-bind="props.action"
           :class="{ 'active-link': hasSubmenu && isActiveRoute(item) }">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
          <span :class="[hasSubmenu && 'pi pi-fw pi-angle-down', hasSubmenu && isActiveRoute(item.items[0][0].items[0].route) ? 'active-submenu' : '']" v-bind="props.submenuicon" />
        </a>
      </template>
    </MegaMenu>
    <!-- MENU END -->

  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref } from "vue";
const router = useRouter()
import { computed } from 'vue';
import VueJwtDecode from 'vue-jwt-decode'

const logout = () => {
  localStorage.removeItem("token"); // Verwende removeItem anstelle von setItem(null)
  router.push("/login");
};

const currentRoute = computed(() => router.currentRoute.value.path);

const isRouteActive = (items) => {
  return items.some(item => {
    // Wenn das Item selbst die aktuelle Route ist
    if (item.route === currentRoute.value) {
      return true;
    }
    // Wenn das Item Unterpunkte hat, prüfe diese rekursiv
    if (item.items) {
      return item.items.some(subItems => {
        return subItems.some(subItem => isRouteActive(subItem.items));
      });
    }
    return false;
  });
};

const isActiveRoute = (item) => {
  // Überprüfe, ob das Hauptmenüitem oder eines seiner Kinder aktiv ist
  return isRouteActive([item]);
};


const items = ref([
  {
    label: 'Jobs Duplizierung',
    icon: 'pi pi-clone',
    items: [
      [
        {
          items: [{ label: 'Jobs',  route: '/jobs' }, { label: 'Konfiguration', route: '/config' }]
        }
      ]
    ]
  },
  {
    label: 'Gehaltsrechner',
    icon: 'pi pi-calculator',
    route: '/params'
  },
  {
    label: 'Berufsgruppen',
    icon: 'pi pi-th-large',
    items: [
      [
        {
          items: [{ label: 'Stellentitel Matching',  route: '/stellentitel-matching' }, { label: 'Konfiguration', route: '/berufsgruppe' }]
        }
      ]
    ],
    scope: 'admin'
  },
  {
    label: 'BA Logs',
    icon: 'pi pi-list',
    route: '/ba-logs',
    scope: 'admin'
  },
  {
    label: 'Google for Jobs',
    icon: 'pi pi-google',
    items: [
      [
        {
          items: [{ label: 'Open Jobs in Queue',  route: '/google-jobs-in-queue' }, { label: 'Scheduler Log', route: '/scheduler-logs' }, { label: 'Scheduler Log Details', route: '/scheduler-log-details' }]
        }
      ]
    ],
    scope: 'admin'
  },
  {
    label: 'Screener Questions',
    icon: 'pi pi-question-circle',
    route: '/indeed-questions',
    scope: 'admin'
  },
  {
    label: 'Logout',
    icon: 'pi pi-power-off',
    command: () => logout()
  }
].filter(x => !x.scope || (x.scope && x.scope === VueJwtDecode.decode(localStorage.getItem("token")).scope)));
</script>
<style scoped>

</style>
