import {createRouter, createWebHistory} from 'vue-router'
import Login from "@/components/Login.vue";

const
    routes = [
        {
            path: '/',
            redirect: '/jobs',
            meta: { requiresAuth: true }
        },
        {
            path: '/jobs',
            component: () => import('../loggedInLayout.vue'),
            meta: { requiresAuth: true },
            children: [
                {
                    path: '',
                    name: 'jobs',
                    meta: {
                        requiresAuth: true,
                        title: 'Jobs'
                    },
                    component: function () {
                        return import('../components/jobs/jobs.vue')
                    }
                },
                {
                    path: '/config',
                    name: 'config',
                    meta: {
                        requiresAuth: true,
                        title: 'Konfiguration'
                    },
                    component: function () {
                        return import('../components/config/config.vue')
                    }
                },
                {
                    path: '/params',
                    name: 'params',
                    meta: {
                        requiresAuth: true,
                        title: 'Gehaltsrechner'
                    },
                    component: function () {
                        return import('../components/params/params.vue')
                    }
                },
                {
                    path: '/ba-logs',
                    name: 'ba-logs',
                    meta: {
                        requiresAuth: true,
                        title: 'BA Logs'
                    },
                    component: function () {
                        return import('../components/ba/ba-logs.vue')
                    }
                },
                {
                    path: '/berufsgruppe',
                    name: 'berufsgruppe-details',
                    meta: {
                        requiresAuth: true,
                        title: 'Konfiguration'
                    },
                    component: function () {
                        return import('../components/berufsgruppe/berufsgruppe-details.vue')
                    }
                },
                {
                    path: '/google-jobs-in-queue',
                    name: 'google-jobs-in-queue',
                    meta: {
                        requiresAuth: true,
                        title: 'Open Jobs in Queue'
                    },
                    component: function () {
                        return import('../components/google_jobs_in_queue/google_jobs_in_queue.vue')
                    }
                },
                {
                    path: '/indeed-questions',
                    name: 'indeed-questions',
                    meta: {
                        requiresAuth: true,
                        title: 'Screener Questions'
                    },
                    component: function () {
                        return import('../components/indeed/indeed_questions.vue')
                    }
                },
                {
                    path: '/scheduler-logs',
                    name: 'scheduler-logs',
                    meta: {
                        requiresAuth: true,
                        title: 'Scheduler Logs'
                    },
                    component: function () {
                        return import('../components/scheduler-logs/scheduler-logs.vue')
                    }
                },
                {
                    path: '/scheduler-log-details/:id?',
                    name: 'scheduler-log-details',
                    meta: {
                        requiresAuth: true,
                        title: 'Scheduler Details'
                    },
                    component: function () {
                        return import('../components/scheduler-log-details/scheduler-log-details.vue')
                    }
                },
                {
                    path: '/stellentitel-matching',
                    name: 'stellentitel-matching',
                    meta: {
                        requiresAuth: true,
                        title: 'Stellentitel Matching'
                    },
                    component: function () {
                        return import('../components/stellentitel-matching/stellentitel-matching.vue')
                    }
                },
                {
                    path: '/ai',
                    name: 'ai',
                    meta: { requiresAuth: true },
                    component: function () {
                        return import('../components/ai/ai.vue')
                    }
                }
            ]
        },
        {
            path: '/',
            meta: { requiresAuth: true },
            component: function () {
                return import('../notLoggedInLayout.vue')
            },
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: Login
                }
            ]
        },
        {
            path: '/:catchAll(.*)*',
            redirect: '/jobs'
        }
    ]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const DEFAULT_TITLE = 'KCS Boosta';
router.beforeEach((to, from, next) => {

    // Pagetitle
    const pageTitle = to.meta.title;
    document.title = pageTitle ? `${DEFAULT_TITLE} - ${pageTitle}` : DEFAULT_TITLE;

    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    //const loggedIn = localStorage.getItem('token');
    const loggedIn = localStorage.getItem("token") && localStorage.getItem("token") !== 'undefined' && localStorage.getItem("token") !== undefined && localStorage.getItem("token") != null && localStorage.getItem("token") !== 'null';
    //console.log(`store.getters.isAuthenticated = ${JSON.stringify(store)}`);
    // trying to access a restricted page + not logged in

    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

// router.beforeEach((to, from, next) => {
//     document.title = to.meta.title || DEFAULT_TITLE;
//     console.log(`to: ${JSON.stringify(to)}; from: ${JSON.stringify(from)}; next: ${JSON.stringify(next)}`);
//     if(to.matched.some(record => record.meta.requiresAuth)) {
//         console.log(`requiresAuth = true; store=${JSON.stringify(store.state)}; isAuthenticated=${store.getters.isAuthenticated}`);
//         if (1 === 1) {
//             next()
//             return
//         }
//         next('/login')
//     } else {
//         next()
//     }
// });

export default router
