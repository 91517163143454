<template>
  <div class="box box--white login">
    <h1 class="mb-5">Erobere die Recruiting-Welt!</h1>

    <form @submit.prevent="onSubmitLogin">
      <div class="flex flex-column gap-2 mb-3">
        <InputText v-model="username" placeholder="Benutzer" class="login__input"/>
      </div>

      <div class="flex flex-column gap-2 mb-5">
        <Password v-model="password" placeholder="Passwort" :feedback="false" class="login__input"/>
      </div>
      <Button type="submit" :disabled="isLoginDisabled" icon="pi pi-lock" label="Login" class="login__button"/>
      <p v-if="error" class="login__error">Zugangsdaten ungültig! Bitte Benutzername und Passwort überprüfen und erneut versuchen.</p>

      <!-- Message when login is disabled -->
      <p v-if="isLoginDisabled" class="login__error">
        Zu viele fehlgeschlagene Versuche. Bitte versuchen Sie es später erneut.
      </p>
    </form>
  </div>
</template>

<script>
import {ref} from "vue";

let username = ref();
let password = ref();
let error = ref();
export default {
  name: "Login",
  data() {
    return {
      username: username,
      password: password,
      error: null,
      loginAttempts: 0,
      isLoginDisabled: false
    }
  },
  methods: {
    onSubmitLogin(user) {
      if (this.isLoginDisabled) {
        return;
      }

      this.loginAttempts++;

      if (this.loginAttempts >= 3) {
        this.isLoginDisabled = true;
        setTimeout(() => {
          this.isLoginDisabled = false;
          this.loginAttempts = 0;
        }, 60000); // 60 seconds
      }

      this.$store.dispatch("auth/login", {username: username.value, password: password.value}).then(
          () => {
            // TODO token should be checked for validity on backend side, needs to be implemented, this is only a simplification for the demo
            if (localStorage.getItem("token") && localStorage.getItem("token") !== undefined &&
                localStorage.getItem("token") != null &&
                localStorage.getItem("token") !== 'null') {
              this.$router.push("/jobs");
            } else {
              console.log("login not successful")
              this.error = true;
            }
          }
      );
    }
  }
}

</script>

<style scoped>

</style>